<template>
  <div>
    <div class="bg-cover bg-top bg-center h-[160px] sm:h-[160px] md:h-[300px] lg:h-[500px]"
         style="background-image: url('http://daisyui.com/tailwind-css-component-profile-1@94w.png')"></div>
    <div class="hero">
      <div class="flex flex-wrap md:container md:mx-auto mt-[-30px] sm:mt-[-30px] md:mt-[-80px] lg:mt-[-100px] md:w-[96vw] lg:w-[75vw]">

        <div class="w-full sm:w-1/1 md:w-1/4 lg:w-1/4 px-2 mb-3">
          <div class="shadow-lg bg-base-100 max-w-sm bg-white border border-gray-200 rounded-lg" style="text-align: -webkit-center">
            <div class="avatar justify-center p-6 pb-0">
              <div class="rounded-full w-24 h-24">
                <img src="http://daisyui.com/tailwind-css-component-profile-1@94w.png" class="rounded-full">
              </div>
            </div>
            <div class="w-full h-18 p-4 text-center desc-txt">
              <p class="font-bold group-hover:text-accent">{{ user.first_name == null ? user.nickname : user.first_name }}</p>
              <p class="p-0 main-text">{{ user.phone }}</p>
            </div>

            <!--              mobile-->
            <!--                        <ul class="menu items-stretch px-3 horizontal hidden-sm-and-up phonemenu">-->
            <div class="md:hidden text-justify">
              <ul class="menu items-stretch px-3 horizontal phonemenu">
                <li>
                  <router-link to="/profile/">
                    <i class="uil uil-user"></i>
                    <!--                                        <span>Хувийн мэдээ</span>-->
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/change-password">
                    <i class="uil uil-unlock-alt"></i>
                    <!--                                        <span>Пин солих</span>-->
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/extend-rights">
                    <i class="uil uil-parcel"></i>
                    <!--                                        <span>Эрх сунгалтууд</span>-->
                    <!--                                        <div class="badge badge-sm bg-accent ml-2">3</div>-->
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/my-fund">
                    <i class="uil uil-database-alt"></i>
                    <!--                                        <span>Миний сан</span>-->
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/viewed-history">
                    <i class="uil uil-history"></i>
                    <!--                                        <span>Үзсэн түүх</span>-->
                  </router-link>
                </li>
              </ul>
            </div>

            <!--              desktop-->
            <div class="hidden md:block text-justify">
              <ul class="menu items-stretch py-3 deskmenu" data-theme="mytheme">
                <li>
                  <router-link to="/profile/">
                    <i class="uil uil-user pr-1"></i>
                    <span>Хувийн мэдээ</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/change-password">
                    <i class="uil uil-unlock-alt pr-1"></i>
                    Пин солих
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/extend-rights">
                    <i class="uil uil-parcel pr-1"></i>
                    Эрх сунгалтууд
                    <!--                                        <div class="badge badge-sm bg-accent ml-2">3</div>-->
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/my-fund">
                    <i class="uil uil-database-alt pr-1"></i>
                    Миний сан
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/viewed-history">
                    <i class="uil uil-history pr-1"></i>
                    Үзсэн түүх
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'Profile',
  components: {},
  data() {
    return {
      activeName: 'first'
    };
  },

  computed: {
    ...mapGetters(['isLoadingAuth', 'user'])
  },

  created() {
    console.log('user information');
    console.log(this.user);
  },

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
};
</script>

<style>
.deskmenu li a {
  /*@apply hover:border-r-4 border-accent;*/
}
.phonemenu li a {
  /*@apply hover:border-b-4 border-accent;*/
}
</style>
